// gatsby-browser.js

// ✅ Stijlen en lettertypen importeren
import "@fontsource/clear-sans";
import "./src/components/main.css";

// ✅ React en Context importeren
import React from "react";
import { LocationProvider } from './src/contexts/LocationContext';

// ✅ Root-element inpakken met de LocationProvider
export const wrapRootElement = ({ element }) => (
  <LocationProvider>
    {element}
  </LocationProvider>
);

// ✅ Google Analytics Configuratie
const GA_TRACKING_ID = process.env.GATSBY_GA_TRACKING_ID; // Zorg ervoor dat dit is ingesteld in je .env

// ✅ Functie om Google Analytics te initialiseren
const initializeAnalytics = () => {
  if (typeof window !== 'undefined' && typeof window.gtag !== 'function') {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', GA_TRACKING_ID, {
        page_path: window.location.pathname,
      });
    };
  }
};

// ✅ Gatsby's onRouteUpdate API om te scrollen en Analytics te triggeren
export const onRouteUpdate = ({ location }) => {
  // 🔹 Scroll naar boven bij routewijziging
  if (location.pathname) {
    window.scrollTo(0, 0);
  }

  // 🔹 Google Analytics-tracking
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;

    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
    } else {
      initializeAnalytics();
    }
  }
};

