module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Een website voor coaches","short_name":"Coachwebsite","description":"De website van online businesscoach Iwan Koenderman, meer klanten online","lang":"nl","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/flavicon.png","cache_busting_mode":"none","icon_options":{"purpose":"any maskable"},"legacy":true,"localize":[{"start_url":"/en/","lang":"en","name":"A website for coaches","short_name":"Coach site","description":"The website of online business coach Iwan Koenderman, get more clients online"}],"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
