import React, { createContext, useContext, useState, useEffect } from 'react';

const LocationContext = createContext(null);

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);
  const [lang, setLang] = useState('nl'); // Standaardtaal is Nederlands

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleLocationChange = () => {
        setLocation(window.location);
        // Stel de taal in op basis van het pad, als het begint met '/en/', dan is de taal Engels
        setLang(window.location.pathname.startsWith('/en/') ? 'en' : 'nl');
      };

      handleLocationChange(); // Stel de initiële waarde in bij het laden van de pagina

      window.addEventListener('popstate', handleLocationChange);
      window.addEventListener('pushState', handleLocationChange);
      window.addEventListener('replaceState', handleLocationChange);

      return () => {
        window.removeEventListener('popstate', handleLocationChange);
        window.removeEventListener('pushState', handleLocationChange);
        window.removeEventListener('replaceState', handleLocationChange);
      };
    }
  }, []);

  // Functie om de taal te wijzigen
  const changeLanguage = (newLang) => {
    setLang(newLang);
    // Hier kun je eventueel de gebruiker doorverwijzen naar de juiste taalpagina
    // Bijvoorbeeld: window.location.href = `/${newLang}/`;
  };

  // Zorg ervoor dat als de location of taal verandert, deze waarden centraal beschikbaar zijn
  return (
    <LocationContext.Provider value={{ location, lang, setLang: changeLanguage }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (context === null) {
    throw new Error('useLocation moet binnen een LocationProvider worden aangeroepen');
  }
  return context;
};
